<template>

    <div class="view">

        <div class="nav">
            
            <logo class="nav-logo" :repeat="4" :size="48" :color="'#FF6400'" />
            <h1 :class="hovered" @click="$router.push('/')"><span>w</span>isseloord</h1>
            <div class="lang nl"></div>
            <div class="lang en"></div>
        </div>

        <div class="top-bar">
            <div class="quote studios">
                <logo class="img-logo" :repeat="1" :size="64" :color="'#21645B'" />
                <span>Kijk rond in onze studios, <br>check de apparatuur en boek de meest geschikte voor het opnemen van jouw gedroomde tracks.</span>
            </div>
            <div class="quote">
                <img src="@/assets/academy-logo.png" class="logo">
                <span>Laat ons jou begeleiden<br> om het meest uit je talent te halen met een van onze gemoduleerde lessen, priv&eacute; of in groepsverband.</span>
            </div>
            <div class="quote">

                <img src="@/assets/community-logo-2.png" class="logo">
                <span>Blijf creatief en sluit je aan bij ons internationale netwerk. Wij organiseren dagelijks schrijfsessies en veel meer...</span>
            </div>
            <div class="quote">
                <span>Muziek maken kost energie<br>en die moet worden aangevuld. Gelukkig kun je heerlijk eten in ons restaurant. En wat drinken natuurlijk...</span>
            </div>
        </div>

        <div class="sections">
            <div class="section studios">
                <h3>Studios</h3>
            </div>
            <div class="section academy">
                <h3>Academy</h3>
            </div>
            <div class="section community">
                <h3>Community</h3>
            </div>
            <div class="section restaurant">
                <h3>Restaurant</h3>
            </div>
        </div>


        <div class="team">
            
            <h2>Ontmoet ons team</h2>

            <circle-svg-text v-for="( member, i ) in team" :pic="'pic-' + (i+1) + '.jpeg'" :border="9" :size="220" :txt="member.name" />

        </div>

        <footer>
            <p>
                Get in touch<br>
                Office Phone<br>
                +31 (0)35 626 4444
            </p>
            <p>
                Malik Berrabah (Music producer/Owner)<br>
                +31 (0)6 14 81 99 85<br>
                Info@wisseloord.nl
            </p>
            <p>
                Studios Location<br>
                Catharina van Renneslaan 10<br>
                1217 CX Hilversum<br>
                The Netherlands
            </p>

        </footer>

    </div>

</template>
<style lang="scss">

.view{

    display: flex;
    flex-direction: column;
    min-height:100vh;
    max-width:1480px;
    margin:0 auto;

    .nav{
        padding:0 16px;
        line-height: 84px;
        height:84px;
        display: flex;

        .nav-logo{
            margin:18px 0;
        }
        h1{
            flex:1;
            font-family: 'Gothic';
            color:#354B57;
            text-transform: uppercase;
            text-align: left;
            margin:0;
            padding:0 12px;
            font-size: 42px;
            span{
                color:#ff6400;
            }
        }
        .lang{
            width:24px;
            height: 24px;
            background: url('@/assets/nl.png') no-repeat center center;
            background-size: cover;
            margin:30px 4px;
            border-radius: 100%;
            font-family: 'Manrope';
            &.en{
                background-image: url('@/assets/en.png');
                &:after{
                    content:'eng';
                }
            }
            &:after{
                content:'ned';
                font-size: 12px;
            }
        }
    }
    .top-bar{
        display: flex;
        margin:8px 0;
        padding:0 8px;

        .quote{
            flex:1;
            background:#eee;
            margin:0 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding:24px;
            font-weight: 250;
            /*text-shadow: 0 0 1px rgba(0,0,0,.3);*/

            font-family: 'Manrope', system-ui;
            font-size: 16px;
            line-height: 165%;
            border-radius: 4px;
            color:#354B57;
            display: flex;
            flex-direction: column;

            img.logo{
                height:64px;
                margin-bottom:8px;
            }
            .img-logo{
                margin-bottom:8px;
            }

            span{
                flex:1;
            }

            &:nth-child(odd){
                background:#f4f4f4;
            }
            &.studios{
                color:#354B57;
            }
        }
    }
    .sections{

        display: flex;
        padding:0 8px;

        .section{
            cursor: pointer;
            flex:1;
            min-height: 370px;
            background-size: auto 108%;
            background-position: center center;
            margin:0 6px;
            filter:grayscale(100%);
            transition: filter .225s, background-size .225s;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;


            &:hover{
                background-size:auto 100%;
                h3{
                    background: rgba(0,0,0,.1);
                    font-weight: 450;
                    text-shadow: 0 0 2px rgba(0,0,0,.9);
                }
            }

            h3{
                font-family: 'Manrope';
                font-size: 36px;
                color:rgba(255, 255, 255, 1);
                margin:0;
                padding: 0;
                font-weight: 300;
                line-height: 80px;
                /*background: rgba(0,0,0,.2);*/
                transition: background .225s, font-weight .225s, text-shadow .225s;
                flex: 1;
                /*text-shadow: 0 0 2px rgba(0,0,0,.9);*/
            }

            &:hover{
                filter:grayscale(0);
            }

            &.studios{
                background-image:url('@/assets/studios.jpeg');
            }
            &.academy{
                background-image:url('@/assets/academy.jpeg');
            }
            &.community{
                background-image:url('@/assets/house.jpeg');
            }
            &.restaurant{
                background-image:url('@/assets/restaurant.jpg');
            }
        }
    }
    .team{
        h2{
            flex:100%;
            font-size: 28px;
            font-weight: 200;
            text-transform: uppercase;
            font-family: 'Manrope','Kurale','Nova Mono';
            margin:0 0 12px 0;
            padding: 0;
            line-height: 80px;
        }
        background: linear-gradient(#ddd, #fcede3);
        margin:12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        flex-wrap: wrap;
    }
    footer{
        background: linear-gradient(#333, #354B57);
        padding:24px;
        margin:0 12px 12px 12px;
        color:#ffffff;
        display: flex;
        p{
            flex:1;
        }
    }
}

</style>    
<script>
// @ is an alias to /src
import Logo from '@/ui/Logo.vue'
import CircleText from '@/ui/CircleText.vue'
import CircleSvgText from '@/ui/CircleSvgText.vue'

export default {
    name: 'HomeView',
    data : () => {
        return {
            hovered:false,
            team : [
                { name : 'Malik Berrabah', function : 'Music Producer-Owner' },
                { name : 'Britt Reynolds', function : 'Managment assistant' },
                { name : 'Martin Castelos', function : 'Floor Manager' },
                { name : 'Arjen Mensinga', function : 'Engineer' },
                { name : 'Davide Ruffini', function : 'Engineer' },
                { name : 'Felix Tournier', function : 'Recording Engineer' },
                { name : 'Matthijs Kiviet', function : 'Engineer' },
                { name : 'Tibor Laho', function : 'Engineer' },
                { name : 'Balazs Robert', function : 'Engineer' },
                { name : 'Alessandro Mazzieri', function : 'Engineer' },
                { name : 'Sam Jones', function : 'Engineer' },
                { name : 'Thijmen Zinkhaan', function : 'Engineer' },
                { name : 'James Castelijn', function : 'Engineer' },
                { name : 'Danny Sykes', function : 'Engineer' },
                { name : 'Steyn Driesenaar', function : 'Engineer' }
            ]
        }
    },
    components: {
        'logo': Logo,
        'circle-svg-text' : CircleSvgText
    },
    methods: {
        
    }
}
</script>
