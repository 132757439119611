<template>

    <div class="circle-text" :style="{ width:size+'px', height:size+'px' }">
        <div class="inner" :style="innerStyle()">
            <div :ref="'i-' + i" v-for="(l,i) in txtSpanned" class="letter" :style="letterStyle()">{{l}}</div>
            <div class="pic" :style="{'background-image' : 'url(' + require(`@/assets/${pic}`) + ')'}"></div>
        </div>
    </div>

</template>
<style lang="scss" scoped>


.circle-text{
    /*background: pink;*/
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    /*border:1px red solid;*/
    .inner{
        /*background: rgba(0,0,0,.3);*/
        display: flex;
        justify-content: center;
        border-radius:100%;
        font-family: 'Nova Mono', "Syne Mono", monospace;
        font-weight: bold;
        color:#354B57;
        .pic{
            width:90%;
            height: 90%;
            margin:5%;
            border-radius:100%;
            background-size: cover;
            background-position: center center;
        }
    }
    .letter{
        position: absolute;
        box-sizing: border-box;
        /*border-bottom:1px red solid;
        border-top:1px green solid;*/
        
    }
}
</style>    
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'CircleSvgText',
    data : () => {
        return {
            
        }
    },
    computed : {
    	txtSpanned(){
            return this.txt.replace(/ /g, '\u00a0').split('');
        }
    },
    components: {
        
    },
    methods: {
        innerStyle(){
            let letterHeight = this.size * (this.border/100);
            let innerBoxSize = this.size * ( ( 100 - ( 2 * this.border ) ) / 100 );
            return {
                'width' : innerBoxSize + 'px',
                'height' : innerBoxSize + 'px',
                'font-size' : letterHeight + 'px'
            }
        },
        letterStyle(){
            let letterHeight = this.size * (this.border/100);
            return {
                'margin-top' : -letterHeight + 'px',
                'height' : letterHeight + 'px',
                'transform-origin' : '50% ' + ( this.size * .5 ) +  'px'
            }
        }

    },
    props : [
    	'size',
        'border',
        'txt',
        'pic'
    ],
    mounted(){
        
        let strWidth = 0;
        let firstWidth;
        let i=0;
        Object.keys(this.$refs).forEach( el => {
            i++;
            if(i==1){
                firstWidth = this.$refs[el][0].offsetWidth;
            }
            strWidth+=this.$refs[el][0].offsetWidth;
        });

        
        let cfr = (this.size * ( ( 100 - ( 2 * this.border ) ) / 100 )) * Math.PI;
        let arcDegrees = 180;
        let arcWidth = cfr / 2;

        let initDeg = ( strWidth / arcWidth ) * 180 * .5;
        
        let firstPercWidth = ( firstWidth / arcWidth );
        let firstArcDegrees = arcDegrees * firstPercWidth * .5;


        let deg = 0;
        Object.keys( this.$refs ).forEach( el => {
           
            let domEl = this.$refs[el][0];
            let w = domEl.offsetWidth;
            let percW = ( w / arcWidth );
            let percDeg = arcDegrees * percW;
            domEl.style.transform = 'rotateZ(' + (deg - initDeg + firstArcDegrees) + 'deg)';
            deg+=percDeg;
            console.log(w,percW,percDeg);
            
        });



        /*let circunferencia = this.txtSpace;
        circunferencia = this.size * Math.PI;
        let arcDegrees = 180;
        let arcWidth = circunferencia / 2;
        console.log(arcWidth);

        let c = 0;
        let deg = 0;
        Object.keys( this.$refs ).forEach( el => {
            c++;
            let domEl = this.$refs[el][0];
            let w = domEl.offsetWidth;
            let percW = ( w / arcWidth );
            let percDeg = arcDegrees * percW;
            domEl.style.transform = 'rotateZ(' + deg + 'deg)';
            deg+=percDeg;
            console.log(w,percW,percDeg);
            
        });*/

        /*let halfRadius = this.txtSpace * .5;
        let degrees = 180;
        let percWidth = strWidth / halfRadius;
        let percDeg = degrees * percWidth;
        console.log(strWidth,halfRadius,degrees,percWidth,percDeg);
        //let deg = 0;
        let initDeg = percDeg * .5;
        let countDeg = 0;
        Object.keys(this.$refs).forEach( el => {
            let w = this.$refs[el][0].offsetWidth;
            countDeg+=(( 100 / this.topTxt.length) * percDeg) / 100;
            this.$refs[el][0].style.transform = 'rotateZ(' + (countDeg - initDeg) + 'deg)';            
        });*/
        
    }
}
</script>
