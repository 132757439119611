<template>

    <div class="logo" :style="{width:size+'px',height:size+'px'}">
        <div class="part p1" :style="{'background-color' : color}"></div>
        <div class="part p2" :style="{'animation-iteration-count':iteration, 'background-color' : color}"></div>
        <div class="part p3" :style="{'background-color' : color}"></div>
        <div class="part p4" :style="{'background-color' : color}"></div>
        <div class="part p5" :style="{'background-color' : color}"></div>
        <div class="part p6" :style="{ 'animation-iteration-count':iteration, border : ( size*.05 ) + 'px ' + color + ' solid'}">
        	<div class="p6-1" :style="{'animation-iteration-count':iteration}">
        		<div class="p6-1-1" :style="{'background-color' : color}">
        			<div class="p6-1-2" :style="{'background-color' : color}"></div>
        		</div>
        	</div>
        </div>
        <div class="part p7" :style="{'border-radius':( size * .015 ) + 'px', 'background-color' : color}"></div>
        <div class="part p8" :style="{'animation-iteration-count':iteration, 'border-left' : ( size * .11 ) + 'px transparent solid',
            'border-right': ( size * .11 ) + 'px transparent solid',
            'border-bottom' : ( size * .025 ) + 'px ' + color + ' solid',
            'border-radius' : ( size * .01 ) + 'px'}">
        	<div class="p8-1" :style="{'background-color' : color, width: ( size * .025) + 'px', height: ( size * .025) + 'px', margin: - (size * .0125 ) + 'px 0 0 ' + -(size * .0125 ) + 'px'}"></div>
        	<div class="p8-2" :style="{'background-color' : color, width: ( size * .22) + 'px', height: ( size * .01) + 'px', 'border-radius' : ( size * .01) + 'px', margin: ( size * .025) + 'px 0 0 ' + - ( size * .11) + 'px'}"></div>
        </div>
        <div class="part p9" :style="{'background-color' : color}"></div>
        <div class="part p10" :style="{'border-radius':( size * .035 ) + 'px', 'background-color' : color}"></div>
        <div class="part p11" :style="{'background-color' : color }"></div>
        <div class="part p12" :style="{'background-color' : color }"></div>
        <div class="part p13">
        	<div class="p13-1" :style="{'animation-iteration-count':iteration,  padding: (size*.02) + 'px', width: (size*.31) + 'px', height : (size * .31) + 'px'}">
        		<div class="p13-circle c1" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c2" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c3" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c4" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c5" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c6" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c7" :style="{'background-color' : color}"></div>
        		<div class="p13-circle c8" :style="{'background-color' : color}"></div>

        		<div class="p13-1-2" :style="{ width:'calc(100% - ' + (size*.04) +  'px)',height:'calc(100% - ' + (size*.04) +  'px)', border : (size*.015) + 'px ' + color + ' solid', 'margin-top':'calc(50% - ' + (size*.01) + 'px)'}"></div>
        		<div class="p13-1-1" :style="{ border : (size*.015) + 'px ' + color + ' solid'}"></div>
        	</div>
        	<!-- <div class="p13-1" :style="{ border : (size*.015) + 'px ' + color + ' solid'}"></div> -->
        </div>
    </div>

</template>
<style lang="scss" scoped>

.logo{
    position: relative;
    overflow: hidden;
    .part{
        position: absolute;
        &.p1{
            width:66%;
            height:13.5%;
            transform: rotate3d(0, 0, 1, -45deg);
            bottom:11.5%;
            left:-2%;
        }
        &.p2{
            width:38%;
            height:13.5%;
            transform: rotate3d(0, 0, 1, 45deg);
            bottom:24%;
            z-index: 3;
            left:29%;
            transform-origin: 10% 100%;
            border-top-left-radius:16% 50%;
            border-bottom-left-radius:16% 50%;
            animation: kick 1s infinite;
        }
        &.p4{
            width:21%;
            height:41%;
            transform: rotate3d(0, 0, 1, -45deg);
            border-top-right-radius:50% 25%;
            border-bottom-right-radius:50% 25%;
            bottom:37.5%;
            left:41%;
        }
        &.p5{
            width:20%;
            height:20%;
            border-radius:100%;
            left:30%;
            top:5.5%;
        }
        &.p6{
            background: rgba(255,255,255,0);
            width:42%;
            height:42%;
            border-radius:100%;
            right:1%;
            top:9%;
            border:50% orange solid;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            perspective: 1000px;
            animation:basehit 1s infinite;

            .p6-1{
            	animation:drum 1s infinite;
            	transform-origin: 50% 100%;
            	height:100%;
            	width:31.5%;
	            display: flex;
	            align-items: center;
            	.p6-1-1{
            		width:100%;
            		height:31.5%;
            		border-radius:100%;
            		display: flex;
            		justify-content: center;

	            	.p6-1-2{
	            		width:25%;
	            		height:200%;
	            		margin-top:50%;
	            	}
            	}
            }
        }
        &.p7{
            width:22%;
            height:2.5%;
            right:11%;
            top:7%;
        }
        &.p8{
            width:0;
            height:0;
            right:11%;
            top:2%;
            border:0;
            border-top:0;
            text-align: left;
            animation:hihat .5s infinite;
            
            .p8-1{
            	position: absolute;
            	background: black;
            	border-radius: 100%;

            }
        }
        &.p9{
            width:10%;
            height:13.5%;
            transform: rotate3d(0, 0, 1, 45deg);
            top:35.9%;
            left:24%;
            border-bottom-right-radius:50% 35%;
        }
        &.p10{
            width:7%;
            height:19%;
            transform: rotate3d(0, 0, 1, -45deg);
            top:29%;
            left:16.9%;
        }
        &.p11{
            width:44%;
            height:2.5%;
            transform: rotate3d(0, 0, 1, -45deg);
            bottom:34.5%;
            right:15%;
        }
        &.p12{
            width:25%;
            height:2.5%;
            transform: rotate3d(0, 0, 1, -45deg);
            top:23.3%;
            right:32%;
        }
        &.p13{
            width:31%;
            height:23%;
            transform: rotate3d(0, 0, 1, -45deg);
            top:19.5%;
            left:-2%;
            overflow:hidden;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
    		perspective: 50px;
            .p13-1{

            	animation:shake .125s infinite;
            	box-sizing: border-box;
            	position: relative;
	        	.p13-circle{
	        		position: absolute;
	        		width:17%;
	        		height:17%;
	        		border-radius:100%;
	        		z-index: 400;
	        		&.c1{
	        			top:42%;
	        			right:1.5%;
	        		}
	        		&.c2{
	        			top:18%;
	        			right:7%;
	        		}
	        		&.c3{
	        			top:4%;
	        			right:27%;
	        		}
	        		&.c4{
	        			top:3%;
	        			left:30%;
	        		}
	        		&.c5{
	        			top:16%;
	        			left:10%
	        		}
	        		&.c6{
	        			top:38%;
	        			left:1%
	        		}
	        		&.c7{
	        			width:16%;
	        			height: 16%;
	        			top:55%;
	        			left:18%
	        		}
	        		&.c8{
	        			width:16%;
	        			height: 16%;
	        			top:54%;
	        			left:64%
	        		}
	        	}
            	.p13-1-1, .p13-1-2{
            		width:100%;
            		height: 100%;
            		border-radius:100%;
            		box-sizing: border-box;
            	}
            	.p13-1-2{
            		position: absolute;
            		z-index: 300;
            	}
            }
        }
    }
}
@keyframes drum{
	0%,20%, 100%{
		transform:rotate3d(0,0,0,0);
	}
	4%{
		transform:rotate3d(1.8,1,-.8,45deg);
	}
	10%{
		transform:rotate3d(0,0,0,0);
	}
	12.5%{
		transform:rotate3d(1.8,1,-.8,-20deg);
	}
}
@keyframes basehit{
	0%{
		transform:scale3d(1.0, 1.0, 1.0);
	}
	10%{
		transform:scale3d(.99, .99, .99);
	}
	12.5%{
		transform:scale3d(1.0, 1.0, 1.0);
	}
}
@keyframes hihat{
	0%{
		transform:translate3d(0,0,0);
	}
	10%{
		transform:translate3d(0,100%,0);
	}
	12.5%{
		transform:translate3d(0, 0, 0);
	}
}
@keyframes kick{
	0%{
		transform:rotate3d(0,0,0);
	}
	10%{
		transform:rotate3d(0,0,1,52deg);
	}
	12.5%{
		transform:rotate3d(0, 0, 0);
	}
}
@keyframes shake{
	0%,50%,100%{
		transform:rotate3d(0,0,0);
	}
	25%{
		transform:rotate3d(1.8,1,-.8,8deg);
	}
	75%{
		transform:rotate3d(1.8,1,-.8,-8deg);
	}
}
</style>    
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'HomeView',
    data : () => {
        return {
            
        }
    },
    computed : {
    	iteration(){
    		return this.repeat || 'infinite';
    	}
    },
    components: {
        //HelloWorld
    },
    methods: {
        
    },
    props : [
    	'size',
    	'color',
    	'repeat'
    ]
}
</script>
