<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&family=Wellfleet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Syne+Mono&family=Wellfleet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Nova+Mono&family=Syne+Mono&family=Wellfleet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kurale&family=Manrope:wght@200..800&family=Nova+Mono&family=Syne+Mono&family=Wellfleet&display=swap');
@font-face {
    font-family: 'Gothic';
    src: url('@/assets/gothiconeonecondensed.ttf') format('truetype');
}
html, body{
    padding:0;
    margin:0;
    height:100vh;
}
body{
    /*background:#222;*/
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width:100%;
  min-height:100%;
  background:linear-gradient(#fff, #f7f7f2);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
