<template>
    <div class="home" :class="{ muted : !BgVideoIsMuted}" ref="home" @click="toggleMute" @mousemove="moveFocus">
        <div @mouseover="hovered = true" @mouseout="hovered = false" @click.stop="$router.push('/site')" class="css-logo" :class="{ hovered : hovered }">
            <logo :size="100" :color="'#FF6400'" />
        </div>
        <h1 @mouseover="hovered = true" @mouseout="hovered = false" @click.stop="$router.push('/site')" class="logo" :class="{ hovered : hovered }"><span>W</span>isseloord</h1>
        <div class="filter" :style="filterBG()">
            <div @mouseover="hovered = true" @mouseout="hovered = false" @click.stop="$router.push('/site')" class="skip-intro" :class="{ hovered : hovered }">Skip intro</div>
        </div>
        <section id="video_landing">
            <iframe title="Wisseloord_Header" src="https://player.vimeo.com/video/639861484?dnt=1&amp;app_id=122963&controls=0&hd=1&autohide=1&loop=0&api=1&background=1&autoplay=1" width="640" height="338" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" id="introvideo"></iframe>
        </section>
    </div>
</template>
<style lang="scss">
    .home{
        width:100vw;
        height:100vh;
        cursor:url('@/assets/unmute.png'), auto;
        &.muted{
            cursor:url('@/assets/mute.png'), auto;
            #video_landing{

                filter:grayscale(0%);
            }
        }
    }
    

    #video_landing {
        z-index: 1;
        background: #000;
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;

        filter:grayscale(100%);
        transition: filter 1s;

        iframe {
            width: 200vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            overflow: hidden;
        }
    }

    .css-logo{
        position: absolute;
        z-index: 100;
        width: 100px;
        height: 100px;
        top:50%;
        left:50%;
        margin:-140px 0 0 -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity:.6;
        transition: opacity .225s;
        cursor:pointer;
        &:hover, &.hovered{
            opacity: 1;
        }
    }

    h1.logo{
        margin:-25px 0 0 -125px;
        padding: 0;
        font-family: 'Gothic';
        color:rgba( 255,255,255,.3);
        font-size:48px;
        text-transform: uppercase;
        position: absolute;
        z-index: 200;
        font-weight: normal;
        top:50%;
        left:50%;
        transition: color .225s;

        span{
            color:#ff6400;
        }

        &:hover, &.hovered{
            cursor: pointer;
            color:#ffffff;
            /*span{
                color:rgba(255,255,255,.93);
            }*/
        }
    }

    .filter{
        position: absolute;
        overflow: hidden;
        z-index: 3;
        width:100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .skip-intro{
            margin-top:120px;
            cursor:pointer;
            text-transform: uppercase;
            font-family: 'Manrope','Exo 2', sans-serif;
            font-size: 20px;
            font-weight: 200;
            color:rgba(255,255,255, .9);
            padding:0 16px;
            border-radius:4px;
            transition: color .225s;
            &:hover, &.hovered{
                color:#f96402;
                font-weight: 350;
            }
        }
    }

    /*..wisseloord{
        width:50vh;
        height: 50vh;
        position: relative;
        img{
            width:100%;
        }
        el{
            position: absolute;
            background:white;
            &.el-1{
                width:3.5%;
                height:4%;
                right:14%;
                bottom:3%;
                border-bottom-left-radius:100% 100%;
                border-bottom-right-radius:100% 100%;
            }
            &.el-2{
                width:3.5%;
                height:1%;
                right:14%;
                bottom:8%;
            }
            &.el-3{
                width:3.5%;
                height:6%;
                right:14%;
                bottom:10%;
            }
            &.el-4{
                width:8%;
                height:22.5%;
                border-top-left-radius:22.5% 8%;
                border-top-right-radius:22.5% 8%;
                border-bottom-left-radius:22.5% 8%;
                border-bottom-right-radius:22.5% 8%;
                right:12%;
                bottom:17%;
            }
            &.el-5{
                width:5%;
                height:25%;
                right:13.4%;
                bottom:39.5%;
                border-top-left-radius:50% 10%;
                border-top-right-radius:50% 10%;
            }
            &.el-6{
                width:41%;
                height:5%;
                right:11.5%;
                bottom:69%;
                transform: rotate3d(0, 0, 1, 30deg);
                border-top-left-radius:7% 41%;
                border-top-right-radius:7% 41%;
                border-bottom-left-radius:7% 41%;
                border-bottom-right-radius:7% 41%;
            }
        }
    }*/
</style>    
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import Logo from '@/ui/Logo.vue'

export default {
    name: 'HomeView',
    data : () => {
        return {
            BgVideoIsMuted : 1,
            filterX : 50,
            filterY : 50,
        }
    },
    components: {
        'logo': Logo
    },
    methods: {
        toggleMute(){
            let video = document.getElementById('introvideo');
            let player = new Vimeo.Player(video);
            this.BgVideoIsMuted = ( this.BgVideoIsMuted ) ? 0 : 1;
            player.setMuted( this.BgVideoIsMuted );
        },
        filterBG(){
            return {
                'background-image' : 'radial-gradient( circle farthest-side at ' + this.filterX + '% ' + this.filterY + '%, rgba(0,0,0,.2), rgba(0,0,0,1) )'
            }
        },
        moveFocus(e){
            e.stopPropagation();
            this.filterX = (e.clientX / this.$refs.home.clientWidth) * 100;
            this.filterY = (e.clientY / this.$refs.home.clientHeight) * 100;
        }
    },
    mounted(){
        let vm = this;
        let video = document.getElementById('introvideo');
        let player = new Vimeo.Player(video);
        player.on('ended', function() {
            vm.$router.push('/site');
        });
    }
}
</script>
